import React from 'react';
import './index.less';

const IconFont = Loader.loadBaseComponent('IconFont');

export default ({ className = '', children, icon = 'icon-S_Edit_Clear', title = '' }) => (
  <div className={`icon-title-view ${className}`}>
    <div className="icon-title-header">
      <IconFont className='icon-title-icon' type={icon} />{ title }
    </div>
    <div className="title-content">
      {children}
    </div>
  </div>
)